/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Delete from '@mui/icons-material/Delete';
import Send from '@mui/icons-material/Send';
import cx from 'classnames';
import { parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setInvoiceId } from '../features/invoice/invoiceSlice';
import { setPostalCode } from '../features/postalCode/postalCodeSlice';
import { Appointment } from '../features/serviceLocation/serviceLocationSlice';
import workOrderSlice, {
  setWorkOrderUnavailabilityReason,
} from '../features/workOrder/workOrderSlice';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import {
  MileageEventType,
  ServiceItem,
  WorkOrder,
  WorkOrderProps,
  WorkOrderStatus,
} from '../interfaces/WorkOrder';
import { invoiceStatus } from '../lang/en/payment.json';
import styles from '../sass/components/WorkOrderDetails.module.scss';
import {
  ROUTE_FRANCHISE_MANAGER_INVOICES,
  ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
} from '../util/constants';
import { convertToCurrency } from '../util/currencyFormat';
import { dayTimeString } from '../util/formatDate';
import {
  BILL_WORK_ORDER,
  CREATE_INSPECTION,
  CREATE_MILEAGE,
  CANCEL_WORK_ORDER,
  GET_FRANCHISEE_WORK_ORDERS,
  GET_POSTAL,
  GET_UNAVAILABILITY_REASON,
  GET_WORK_ORDER,
  UPDATE_SCHEDULED_TIME,
  UPDATE_USER_VEHICLE_VIN_WITH_ID,
  UPDATE_WORK_ORDER_VEHICLE_ID,
  UPDATE_LINE_ITEMS,
  UPDATE_LINE_ITEMS_PRICES,
  CONFIRM_WORK_ORDER,
  CREATE_AWS_PATH,
  UPDATE_LINE_ITEM_LOCATION,
  ASSIGN_WORK_ORDER,
  UPDATE_WORK_ORDER_NOTES,
  UPDATE_WORK_ORDER_PO_NUMBER,
} from '../util/gql';
import { getCurrentVehicleSubscription } from '../util/subcriptionHelper';
import AppointmentDatePicker from './AppointmentDatePicker';
import Badge from './Badge';
import Button from './Button';
import ErrorModalContainer from './CompleteModal';
import EditVehicleEngineContainer from './EditVehicleEngineContainer';
import EditLineItemsContainer from './EditLineItemsContainer';
import CompleteWorkOrderContainer from './CompleteWorkOrderContainer';
import CompleteInspectionContainer from './CompleteInspectionContainer';
import DetailsRow from './DetailsRow';
import ListRow from './ListRow';
import Modal from './Modal';
import { ServiceLocation } from '../interfaces/Components';
import AssignWorkOrderContainer from './AssignWorkOrderContainer';
import EditNotesContainer from './EditNotesContainer';
import EditPoNumberContainer from './EditPoNumberContainer';
import CancelWorkOrderContainer from './CancelWorkOrderContainer';
import ConfirmationModal from './ConfirmationModal';

export default function WorkOrderDetails() {
  const [workOrderDetails, setWorkOrderDetails] = useState<WorkOrderProps>();
  const [
    completeWorkOrderModal,
    setCompleteWorkOrderModal,
  ] = useState<boolean>(false);
  const [
    editVehicleEngineModal,
    setEditVehicleEngineModal,
  ] = useState<boolean>(false);
  const [
    editLineItemModal,
    setEditLineItemsModal,
  ] = useState<boolean>(false);
  const [
    editPoNumberModal,
    setEditPoNumberModal,
  ] = useState<boolean>(false);
  const [
    editNotesModal,
    setEditNotesModal,
  ] = useState<boolean>(false);
  const [
    completeInspectionModal,
    setCompleteInspectionModal,
  ] = useState<boolean>(false);

  const [
    confirmWorkOrder,
    setConfirmWorkOrder,
  ] = useState<boolean>(false);

  const [
    cancelWorkOrderModal,
    setCancelWorkOrderModal,
  ] = useState<boolean>(false);

  const [
    assignWorkOrderModal,
    setAssignWorkOrderModal,
  ] = useState<boolean>(false);

  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [editScheduledTime, setEditScheduledTime] = useState<boolean>(false);
  const [dateTime, setDateTime] = useState<Appointment>();

  const [canCompleteOrder, setCanCompleteOrder] = useState<boolean>(false);
  const [isInspected, setIsinspected] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [
    isValidAddress,
    setIsValidAddress,
  ] = useState<undefined | boolean>(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams();

  const workOrderId = useAppSelector((state) => state.workOrder.id)
    || Number(id);

  const unavailabilityReason = useAppSelector(
    (state) => state.workOrder.unavailabilityReason,
  );

  const vehicleName = (
    { vehicle }: WorkOrder,
  ) => `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.engine}`;

  const serviceName = (
    { vehicle, lineItems }: WorkOrder, code: string,
  ) => lineItems
    .map((lineItem) => (lineItem.service?.serviceType.name
     === 'OIL_CHANGE'
      ? `${lineItem.name} - ${vehicle.oilVolume}${code}
     of oil` : lineItem.name));

  const backToWorkOrderList = (): void => navigate(
    ROUTE_FRANCHISE_MANAGER_WORK_ORDERS,
  );

  const setInvoiceDetails = () => {
    if (workOrderDetails?.invoiceId) {
      const { invoiceId } = workOrderDetails;
      dispatch(setInvoiceId(invoiceId));
      navigate(`${ROUTE_FRANCHISE_MANAGER_INVOICES}/${invoiceId}`);
    }
  };

  const [updatePostalCode] = useLazyQuery(GET_POSTAL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { isInService, code } = data.getPostal;
      if (isInService) {
        dispatch(setPostalCode(code));
      }
      setIsValidAddress(isInService);
    },
  });

  const [getUnavailabilityReason] = useLazyQuery(GET_UNAVAILABILITY_REASON, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { reason } = data.getUnavailabilityReason;
      dispatch(setWorkOrderUnavailabilityReason(reason));
    },
  });

  const getWorkOrderLineItems = (workOrder: WorkOrder) => workOrder
    .lineItems
    .filter((lineItem) => lineItem.service);

  const [getWorkOrder, { loading }] = useLazyQuery(GET_WORK_ORDER, {
    onCompleted: (data) => {
      const workOrder: WorkOrder = data?.getWorkOrderById;
      setCanCompleteOrder(new Date() > new Date(workOrder.scheduledAt));
      if (workOrder.isInspected === true) {
        setIsinspected(true);
      }
      if (workOrder.isConfirmed === true) {
        setIsConfirmed(true);
      }
      setDateTime({
        date: workOrder.scheduledAt.toString(),
        routeId: workOrder.address.postalCode,
      });
      updatePostalCode({ variables: { code: workOrder.address.postalCode } });
      setWorkOrderDetails({
        id: workOrder.id,
        user: workOrder.user.email,
        userId: workOrder.user.id,
        userDiscountAmount: workOrder.user.discountAmount,
        userDiscountType: workOrder.user.discountType,
        address: `${workOrder.address.street}, ${workOrder.address.postalCode} ${workOrder.address.city}, ${workOrder.address.province}`,
        contactName: workOrder.contact.name,
        contactEmail: workOrder.contact.email,
        contactPhone: workOrder.contact.phoneNumber,
        vehicle: vehicleName(workOrder),
        oilWeight: workOrder.vehicle.recommendedOil.weight,
        vehicleId: workOrder.vehicle.id,
        userVehicleId: workOrder.userVehicle.id,
        vin: workOrder.userVehicle.vin,
        name: workOrder.userVehicle.name,
        franchise: workOrder.franchise.name,
        franchiseId: workOrder.franchise.id,
        scheduledAt: dayTimeString(workOrder.scheduledAt),
        notes: workOrder.notes,
        cancelReason: workOrder.cancelReason,
        proofOfCancelLocation: workOrder.proofOfCancelLocation,
        status: workOrder.status,
        poNumber: workOrder.poNumber,
        invoiceStatus: invoiceStatus[
          workOrder.invoice?.status as keyof typeof invoiceStatus
        ],
        billedAmount: convertToCurrency(workOrder.invoice?.billedAmount),
        invoiceId: workOrder.invoice?.id,
        lineitems: getWorkOrderLineItems(workOrder),
        services: serviceName(workOrder,
          (workOrder.address.countryCode === 'CA' ? 'L' : 'QT')),
        unavailabilityReason,
        vehicleSubscription: getCurrentVehicleSubscription(
          workOrder.userVehicle.subscriptions,
        ),
        engine: workOrder.vehicle.engine,
        year: workOrder.vehicle.year,
        model: workOrder.vehicle.model,
        make: workOrder.vehicle.make,
      });
    },
    onError: backToWorkOrderList,
    fetchPolicy: 'network-only',
  });

  const [completeWorkOrder] = useMutation(BILL_WORK_ORDER, {
    onCompleted: () => {
      setCompleteWorkOrderModal(false);
      backToWorkOrderList();
    },
    onError: () => {
      setCompleteWorkOrderModal(false);
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const [createMileage] = useMutation(CREATE_MILEAGE, {
    onError: () => {
      setCompleteWorkOrderModal(false);
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const [cancelWorkOrder] = useMutation(CANCEL_WORK_ORDER, {
    variables: {
      workOrderId: workOrderDetails?.id,
      cancelReason: workOrderDetails?.cancelReason,
      proofOfCancelLocation: workOrderDetails?.proofOfCancelLocation,
    },
    onCompleted: () => backToWorkOrderList(),
    onError: () => setErrorModal(true),
    fetchPolicy: 'network-only',
  });

  const [updateVin] = useMutation(UPDATE_USER_VEHICLE_VIN_WITH_ID, {
    onError: () => {
      setCompleteWorkOrderModal(false);
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });
  const [updateAwsPath] = useMutation(CREATE_AWS_PATH, {
    onError: () => {
      setCompleteWorkOrderModal(false);
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const [updateScheduledTime] = useMutation(UPDATE_SCHEDULED_TIME, {
    variables: {
      id: workOrderDetails?.id,
      scheduledAt: parseISO(dateTime?.date as string),
    },
    onCompleted: (data) => {
      dispatch(setWorkOrderUnavailabilityReason(
        data?.updateScheduledTime.reason,
      ));
      getWorkOrder({ variables: { id: workOrderId } });
    },
  });

  const [createInspection] = useMutation(CREATE_INSPECTION, {
    onError: () => {
      setCompleteWorkOrderModal(false);
      setErrorModal(true);
    },
    onCompleted: (data) => {
      getWorkOrder({ variables: { id: workOrderId } });
    },
  });

  const [assignWorkOrder] = useMutation(ASSIGN_WORK_ORDER, {
    onError: () => {
      setCompleteWorkOrderModal(false);
      setErrorModal(true);
    },
    onCompleted: () => backToWorkOrderList(),
    fetchPolicy: 'network-only',
  });

  const saveScheduledTime = () => {
    setEditScheduledTime(false);
    updateScheduledTime();
  };

  const getEventType = (): MileageEventType => {
    const isOilChange = workOrderDetails?.lineitems.some(
      (item) => item.service.serviceType.name === 'OIL_CHANGE',
    );
    return isOilChange ? 'OIL_CHANGE' : 'USER';
  };

  const [updateVehicleId] = useMutation(UPDATE_WORK_ORDER_VEHICLE_ID, {
    onCompleted: (data) => {
      getWorkOrder({ variables: { id: workOrderId } });
    },
    fetchPolicy: 'network-only',
  });

  const [updateLineItems] = useMutation(UPDATE_LINE_ITEMS, {
    onCompleted: (data) => {
      getWorkOrder({ variables: { id: workOrderId } });
    },
    fetchPolicy: 'network-only',
  });

  const [updateLineItemsPrices] = useMutation(UPDATE_LINE_ITEMS_PRICES, {
    onCompleted: (data) => {
      getWorkOrder({ variables: { id: workOrderId } });
    },
    fetchPolicy: 'network-only',
  });

  const [updatePoNumber] = useMutation(UPDATE_WORK_ORDER_PO_NUMBER, {
    onCompleted: (data) => {
      getWorkOrder({ variables: { id: workOrderId } });
    },
    fetchPolicy: 'network-only',
  });

  const [updateNotes] = useMutation(UPDATE_WORK_ORDER_NOTES, {
    onCompleted: (data) => {
      getWorkOrder({ variables: { id: workOrderId } });
    },
    fetchPolicy: 'network-only',
  });

  const [updateLineItemLocation] = useMutation(UPDATE_LINE_ITEM_LOCATION, {
    onError: () => {
      setErrorModal(true);
    },
  });

  const handleEditVehicleId = async (vehicleId: number): Promise<void> => {
    if (vehicleId) {
      try {
        await updateVehicleId({
          variables: {
            vehicleId,
            id: workOrderDetails?.id,
          },
        });

        await updateLineItemsPrices({
          variables: {
            id: workOrderDetails?.id,
          },
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error updating vehicle ID or line items prices:', error);
      }
    }
  };

  const handleEditLineItems = (
    serviceItem: ServiceItem[] | undefined,
    poNumber?: string,
  ):
  void => {
    if (serviceItem) {
      const serviceIds = serviceItem.map((item) => {
        // eslint-disable-next-line max-len
        const ids = item.serviceId !== undefined ? item.serviceId : item.id;
        const name = item.name;
        const tax = item.tax;
        const description = item.description;
        const quantity = item.quantity;
        const price = Math.round(item.price * 100);

        // const discountedPrice = item.discountedPrice;
        return {
          ids, name, tax, description, quantity, price,
        };
      });

      updateLineItems(
        {
          variables: {
            serviceIds,
            id: workOrderDetails?.id,
            poNumber,
          },
        },
      );
    }
  };

  const handleEditPoNumber = (
    poNumber: string,
  ):
  void => {
    if (poNumber) {
      updatePoNumber(
        {
          variables: {
            poNumber,
            id: workOrderDetails?.id,
          },
        },
      );
    }
  };

  const handleEditNotes = (
    notes: string,
  ):
  void => {
    if (notes) {
      updateNotes(
        {
          variables: {
            notes,
            id: workOrderDetails?.id,
          },
        },
      );
    }
  };

  const handleCancel = (
    cancelReason: string,
    proofOfCancelLocation: string,
  ):
  void => {
    cancelWorkOrder(
      {
        variables: {
          workOrderId,
          cancelReason,
          proofOfCancelLocation,
        },
        refetchQueries: () => [{
          query: GET_FRANCHISEE_WORK_ORDERS,
          variables: {
            franchiseId: workOrderDetails?.franchiseId,
            status: WorkOrderStatus.assigned,
            pageNumber: 1,
          },
        }],
      },
    );
  };

  const handleAssignWorkOrder = (
    assignedFranchise: number,
  ):
  void => {
    assignWorkOrder(
      {
        variables: {
          workOrderId,
          assignedFranchise,
        },
        refetchQueries: () => [{
          query: GET_FRANCHISEE_WORK_ORDERS,
          variables: {
            franchiseId: workOrderDetails?.franchiseId,
            status: WorkOrderStatus.assigned,
            pageNumber: 1,
          },
        }],
      },
    );
  };
  const handleCompleteWorkOrder = (
    discountType: string,
    discountAmount: number,
    subscriptionTypeId?: number,
    poNumber?: string,
  ):
  void => {
    completeWorkOrder(
      {
        variables: {
          workOrderId,
          discountAmount,
          discountType,
          subscriptionTypeId,
          poNumber,
        },
        refetchQueries: () => [{
          query: GET_FRANCHISEE_WORK_ORDERS,
          variables: {
            franchiseId: workOrderDetails?.franchiseId,
            status: WorkOrderStatus.assigned,
            pageNumber: 1,
          },
        }],
      },
    );
  };
  const handleCompleteInspection = (
    vin: string | undefined,
    mileage: number | undefined,
    inspectionData?: {
      tireConditionFrontLeft?: number,
      tireConditionFrontRight?: number,
      tireConditionRearLeft?: number,
      tireConditionRearRight?: number,
      brakeConditionFrontLeft?: number,
      brakeConditionFrontRight?: number,
      brakeConditionRearLeft?: number,
      brakeConditionRearRight?: number,
      windshieldsCondition?: number,
      notes?: string
      tireIdNumberFrontLeft?: number,
      tireIdNumberFrontRight?: number,
      tireIdNumberRearLeft?: number,
      tireIdNumberRearRight?: number,
      tireSize?: number,
      engineHours?: number,
    },
    mileageLocation?: string,
    vinLocation?: string,
    vehicleLocation?: string,
    oilCapLocation?: string,
    oilFilterLocation?: string,
    oilFilterId?: string,
    checkedFrontLeft?: boolean,
    checkedFrontRight?: boolean,
    checkedRearLeft?: boolean,
    checkedRearRight?: boolean,
    brakeFluid?: boolean,
    windshieldFluid?: boolean,
    leakInspection?: boolean,
    vehicleLocationFrontLeft?: string,
    vehicleLocationFrontRight?: string,
    vehicleLocationRearLeft?: string,
    vehicleLocationRearRight?: string,
    tinLocationFrontLeft?: string,
    tinLocationFrontRight?: string,
    tinLocationRearLeft?: string,
    tinLocationRearRight?: string,
    servicesLocation?: ServiceLocation[],
    cabinFilterId?: string,
    engineFilterId?: string,
    discountType?: string,
    discountAmount?: number,
    subscriptionTypeId?: number,
    poNumber?: string,
  ):
    void => {
    if (mileage) {
      createMileage(
        {
          variables: {
            userVehicleId: workOrderDetails?.userVehicleId,
            mileage,
            workOrderId: workOrderId || workOrderDetails?.id,
            eventType: getEventType(),
          },
        },
      );
    }
    if (vin !== workOrderDetails?.vin) {
      updateVin(
        { variables: { userVehicleId: workOrderDetails?.userVehicleId, vin } },
      );
    }
    if (mileageLocation || vinLocation || vehicleLocationFrontLeft || oilCapLocation) {
      updateAwsPath(
        {
          variables: {
            id: workOrderDetails?.id,
            mileageLocation,
            vinLocation,
            vehicleLocation,
            oilCapLocation,
            vehicleLocationFrontLeft,
            vehicleLocationFrontRight,
            vehicleLocationRearLeft,
            vehicleLocationRearRight,
            tinLocationFrontLeft,
            tinLocationFrontRight,
            tinLocationRearLeft,
            tinLocationRearRight,
            oilFilterLocation,
          },
        },
      );
    }
    if (servicesLocation) {
      const proofOfFixLocation = servicesLocation;
      updateLineItemLocation(
        {
          variables: {
            proofOfFixLocation,
            id: workOrderDetails?.id,
          },
        },
      );
    }
    if (inspectionData && Object.keys(inspectionData).length > 0) {
      createInspection({
        variables: {
          workOrderId: workOrderDetails?.id,
          userVehicleId: workOrderDetails?.userVehicleId,
          ...inspectionData.tireConditionFrontLeft
            // eslint-disable-next-line max-len
            && { tireConditionFrontLeft: inspectionData.tireConditionFrontLeft },
          ...inspectionData.tireConditionFrontRight
            // eslint-disable-next-line max-len
            && { tireConditionFrontRight: inspectionData.tireConditionFrontRight },
          ...inspectionData.tireConditionRearLeft
            && { tireConditionRearLeft: inspectionData.tireConditionRearLeft },
          ...inspectionData.tireConditionRearRight
            // eslint-disable-next-line max-len
            && { tireConditionRearRight: inspectionData.tireConditionRearRight },
          ...inspectionData.brakeConditionFrontLeft
            // eslint-disable-next-line max-len
            && { brakeConditionFrontLeft: inspectionData.brakeConditionFrontLeft },
          ...inspectionData.brakeConditionFrontRight
            // eslint-disable-next-line max-len
            && { brakeConditionFrontRight: inspectionData.brakeConditionFrontRight },
          ...inspectionData.brakeConditionRearLeft
            // eslint-disable-next-line max-len
            && { brakeConditionRearLeft: inspectionData.brakeConditionRearLeft },
          ...inspectionData.brakeConditionRearRight
            // eslint-disable-next-line max-len
            && { brakeConditionRearRight: inspectionData.brakeConditionRearRight },
          ...inspectionData.windshieldsCondition
            && { windshieldsCondition: inspectionData.windshieldsCondition },
          ...inspectionData.notes && { notes: inspectionData.notes },
          ...inspectionData.tireIdNumberFrontLeft && { tireIdNumberFrontLeft: inspectionData.tireIdNumberFrontLeft },
          ...inspectionData.tireIdNumberFrontRight && { tireIdNumberFrontRight: inspectionData.tireIdNumberFrontRight },
          ...inspectionData.tireIdNumberRearLeft && { tireIdNumberRearLeft: inspectionData.tireIdNumberRearLeft },
          ...inspectionData.tireIdNumberRearRight && { tireIdNumberRearRight: inspectionData.tireIdNumberRearRight },
          ...inspectionData.tireSize && { tireSize: inspectionData.tireSize },
          ...inspectionData.engineHours && { engineHours: inspectionData.engineHours },
          checkedFrontLeft,
          checkedFrontRight,
          checkedRearLeft,
          checkedRearRight,
          windshieldFluid,
          brakeFluid,
          leakInspection,
          oilFilterId,
          cabinFilterId,
          engineFilterId,
        },
      });
    }
    completeWorkOrder(
      {
        variables: {
          workOrderId,
          discountAmount,
          discountType,
          subscriptionTypeId,
          poNumber,
        },
        refetchQueries: () => [{
          query: GET_FRANCHISEE_WORK_ORDERS,
          variables: {
            franchiseId: workOrderDetails?.franchiseId,
            status: WorkOrderStatus.assigned,
            pageNumber: 1,
          },
        }],
      },
    );
    setIsinspected(true);
  };

  useEffect(() => {
    if (!unavailabilityReason && workOrderId) {
      getUnavailabilityReason({ variables: { id: workOrderId } });
    }
  }, [getUnavailabilityReason, unavailabilityReason, workOrderId]);

  useEffect(() => {
    if (!workOrderId) backToWorkOrderList();
    getWorkOrder({ variables: { id: workOrderId } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWorkOrder, workOrderId]);

  const handleOnCloseErrorModal = (): void => {
    setErrorModal(false);
    backToWorkOrderList();
  };

  // eslint-disable-next-line max-len
  const [getConfirmation, { data: confirmationData, error }] = useLazyQuery(CONFIRM_WORK_ORDER, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setIsConfirmed(true);
      getWorkOrder({ variables: { id: workOrderId } });
    },
  });

  useEffect(() => {
    if (confirmWorkOrder === true) {
      getConfirmation({ variables: { id: workOrderDetails?.id } });
    }
  }, [confirmWorkOrder, getConfirmation]);

  const Loading = () => (
    <p className={styles.loading}>Loading...</p>
  );

  const CompleteWorkOrderModal = () => (
    <Modal
      open={completeWorkOrderModal}
      onClose={() => setCompleteWorkOrderModal(false)}
      disableBackdropClick
    >
      <CompleteWorkOrderContainer
        workOrderDetails={workOrderDetails}
        onClose={() => setCompleteWorkOrderModal(false)}
        onSubmit={handleCompleteWorkOrder}
      />
    </Modal>
  );

  const AssignWorkOrderModal = () => (
    <Modal
      open={assignWorkOrderModal}
      onClose={() => setAssignWorkOrderModal(false)}
      disableBackdropClick
    >
      <AssignWorkOrderContainer
        onClose={() => setAssignWorkOrderModal(false)}
        onSubmit={handleAssignWorkOrder}
        franchiseId={workOrderDetails?.franchiseId}
      />
    </Modal>
  );

  const CompleteInspectionModal = () => (
    <Modal
      open={completeInspectionModal}
      onClose={() => setCompleteInspectionModal(false)}
      disableBackdropClick
    >
      <CompleteInspectionContainer
        workOrderDetails={workOrderDetails}
        onClose={() => setCompleteInspectionModal(false)}
        onSubmit={handleCompleteInspection}
      />
    </Modal>
  );

  const EditVehicleEngineModal = () => (
    <Modal
      open={editVehicleEngineModal}
      onClose={() => setEditVehicleEngineModal(false)}
      disableBackdropClick
    >
      <EditVehicleEngineContainer
        workOrderDetails={workOrderDetails}
        setWorkOrderDetails={setWorkOrderDetails}
        onClose={() => setEditVehicleEngineModal(false)}
        onSubmit={handleEditVehicleId}
      />
    </Modal>
  );
  const EditLineItemsModal = () => (
    <Modal
      open={editLineItemModal}
      onClose={() => setEditLineItemsModal(false)}
      disableBackdropClick
    >
      <EditLineItemsContainer
        workOrderDetails={workOrderDetails}
        setWorkOrderDetails={setWorkOrderDetails}
        onClose={() => setEditLineItemsModal(false)}
        onSubmit={handleEditLineItems}
      />
    </Modal>
  );
  const EditPoNumberModal = () => (
    <Modal
      open={editPoNumberModal}
      onClose={() => setEditPoNumberModal(false)}
      disableBackdropClick
    >
      <EditPoNumberContainer
        workOrderDetails={workOrderDetails}
        setWorkOrderDetails={setWorkOrderDetails}
        onClose={() => setEditPoNumberModal(false)}
        onSubmit={handleEditPoNumber}
      />
    </Modal>
  );
  const EditNotesModal = () => (
    <Modal
      open={editNotesModal}
      onClose={() => setEditNotesModal(false)}
      disableBackdropClick
    >
      <EditNotesContainer
        workOrderDetails={workOrderDetails}
        setWorkOrderDetails={setWorkOrderDetails}
        onClose={() => setEditNotesModal(false)}
        onSubmit={handleEditNotes}
      />
    </Modal>
  );

  const CancelWorkOrderModal = () => (
    <Modal
      open={cancelWorkOrderModal}
      onClose={() => setCancelWorkOrderModal(false)}
      disableBackdropClick
    >
      <CancelWorkOrderContainer
        workOrderDetails={workOrderDetails}
        setWorkOrderDetails={setWorkOrderDetails}
        onClose={() => setCancelWorkOrderModal(false)}
        onSubmit={handleCancel}
      />
    </Modal>
  );

  const CompleteWorkOrderErrorModal = () => (
    <ErrorModalContainer
      onClose={handleOnCloseErrorModal}
      invoiceId={null}
      currentReader={null}
      open={errorModal}
      title="Complete Work Order"
      message="Error: Could not complete work order, try again later."
    />
  );
  const contactList = [
    workOrderDetails?.contactName ?? '',
    workOrderDetails?.contactEmail ?? '',
    workOrderDetails?.contactPhone ?? '',
  ];
  const displayName = workOrderDetails?.name ? `Personal Identifier: ${workOrderDetails.name}` : '';
  const vehicleList = [
    workOrderDetails?.vehicle ?? '',
    `Oil weight: ${workOrderDetails?.oilWeight}` ?? '',
    displayName,
  ];
  const WorkOrderDetailsRow = () => (
    <div className={styles.workOrderDetailsContainer}>
      <div className={styles.backButtonContainer}>
        <div className={styles.backButtonContent}>
          <Button
            className={cx(styles.caret)}
            type="button"
            onClick={backToWorkOrderList}
            variant="icon"
          >
            <Arrow />
          </Button>
          <h5>
            ID
            {' '}
            {workOrderDetails?.id}
          </h5>
          {unavailabilityReason && unavailabilityReason !== 'Available' && (
          <Badge
            variant="primary"
            className={styles.unavailabilityReasonBadge}
          >
            {unavailabilityReason}
          </Badge>
          )}
          { workOrderDetails?.status === WorkOrderStatus.assigned && (
          <>
            <Button
              className={styles.assignButton}
              variant="icon"
              onClick={() => setAssignWorkOrderModal(true)}
            >
              Assign Work Order
              {' '}
              <Send />
            </Button>
            <AssignWorkOrderModal />
          </>
          )}
          { workOrderDetails?.status === WorkOrderStatus.assigned && isInspected === false && (
            <>
              <Button
                className={styles.deleteButton}
                variant="icon"
                onClick={() => setCancelWorkOrderModal(true)}
              >
                <Delete />
              </Button>
              <CancelWorkOrderModal />
            </>
          )}
        </div>
      </div>
      { workOrderDetails?.status === 'COMPLETE' && workOrderDetails && (
        <DetailsRow
          title="PO Number"
          text={workOrderDetails?.poNumber || 'No PO Number'}
          isItalicText={!workOrderDetails?.poNumber}
        >
          <Button
            onClick={() => setEditPoNumberModal(true)}
            variant="primary"
            className={styles.editButton}
          >
            Edit
          </Button>
          {workOrderDetails && (
            <EditPoNumberModal />
          )}
        </DetailsRow>
      )}
      <DetailsRow
        title="Customer"
        text={workOrderDetails?.user}
      />
      <DetailsRow
        title="Address"
        text={workOrderDetails?.address}
      />
      <ListRow
        title="Contact"
        list={contactList}
      />
      <ListRow
        title="Vehicle"
        list={vehicleList}
      >
        {workOrderDetails?.status === 'ASSIGNED'
        && isValidAddress && workOrderDetails && (
          <Button
            onClick={() => setEditVehicleEngineModal(true)}
            variant="primary"
            className={styles.editButton}
          >
            Edit
          </Button>
        )}
        {workOrderDetails && (
          <EditVehicleEngineModal />
        )}
      </ListRow>
      <ListRow
        title={editScheduledTime ? '' : 'Services'}
        list={workOrderDetails?.services}
      >
        {workOrderDetails?.status === 'ASSIGNED'
        && isValidAddress && workOrderDetails && (
          <Button
            onClick={() => setEditLineItemsModal(true)}
            variant="primary"
            className={styles.editButton}
          >
            Edit
          </Button>
        )}
        {workOrderDetails && (
          <EditLineItemsModal />
        )}
      </ListRow>
      <DetailsRow
        title="Franchise"
        text={workOrderDetails?.franchise}
      />
      {editScheduledTime && (
      <h4 className={styles.editScheduledAtTitle}>
        Select a new date:
      </h4>
      )}
      <DetailsRow
        title={editScheduledTime ? '' : 'Scheduled At'}
        text={editScheduledTime ? '' : workOrderDetails?.scheduledAt}
      >
        {!editScheduledTime && workOrderDetails?.status === 'ASSIGNED'
        && isValidAddress && (
        <Button
          onClick={() => setEditScheduledTime(true)}
          className={styles.editButton}
        >
          Edit
        </Button>
        )}
        {editScheduledTime && (
        <div className={styles.editScheduledAt}>
          <AppointmentDatePicker
            onSelect={(e) => {
              setDateTime({ date: e.date, routeId: e.routeId });
            }}
            value={dateTime}
            timeNoLongerAvailable={false}
          />
          <Button
            onClick={saveScheduledTime}
            className={styles.saveCancelButton}
          >
            Save
          </Button>
          <Button
            onClick={() => setEditScheduledTime(false)}
            className={styles.saveCancelButton}
          >
            Cancel
          </Button>

        </div>
        )}
      </DetailsRow>
      {workOrderDetails?.invoiceStatus && (
        <>
          <DetailsRow
            title="Invoice Status"
            text={workOrderDetails?.invoiceStatus}
          >
            <Button
              onClick={setInvoiceDetails}
              className="payInvoice"
            >
              Invoice Details
            </Button>
          </DetailsRow>
          <DetailsRow
            title="Invoice Value"
            text={workOrderDetails?.billedAmount}
          />
        </>
      )}
      <DetailsRow
        title="Notes"
        text={workOrderDetails?.notes || 'No notes'}
        isItalicText={!workOrderDetails?.notes}
      >
        {workOrderDetails?.status === 'ASSIGNED'
        && isValidAddress && workOrderDetails && (
          <Button
            onClick={() => setEditNotesModal(true)}
            variant="primary"
            className={styles.editButton}
          >
            Edit Notes
          </Button>
        )}
      </DetailsRow>
      {workOrderDetails && (
      <EditNotesModal />
      )}
      {workOrderDetails?.status === WorkOrderStatus.assigned
      && isConfirmed === false && (
      <div className={styles.completeWorkOrderButtonContainer}>
        <Button
          onClick={() => setConfirmWorkOrder(true)}
          variant="primary"
          className="confirmWorkOrder"
        >
          Confirm Order
        </Button>
      </div>
      )}
      {canCompleteOrder && (
        <>
          {workOrderDetails?.status === WorkOrderStatus.assigned
          && isInspected === false && (
          <div className={styles.completeWorkOrderButtonContainer}>
            <Button
              onClick={() => setCompleteInspectionModal(true)}
              variant="primary"
              className="completeInspection"
            >
              Vehicle Inspection
            </Button>
          </div>
          )}
          {workOrderDetails?.status === WorkOrderStatus.assigned
          && isInspected === true && (
          <div className={styles.completeWorkOrderButtonContainer}>
            <Button
              onClick={() => setCompleteWorkOrderModal(true)}
              variant="primary"
              className="completeWorkOrder"
            >
              Complete Order
            </Button>
          </div>
          )}
          <CompleteInspectionModal />
          <CompleteWorkOrderModal />
          <CompleteWorkOrderErrorModal />
        </>
      )}
    </div>
  );

  return (
    <div>
      {loading ? <Loading /> : <WorkOrderDetailsRow />}
    </div>
  );
}

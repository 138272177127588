import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import {
  setWorkOrderId,
  setWorkOrderUnavailabilityReason,
} from '../features/workOrder/workOrderSlice';
import { ReactComponent as Arrow } from '../images/chevron_down_small.svg';
import { ServiceType, WorkOrder } from '../interfaces/WorkOrder';
import styles from '../sass/components/WorkOrderRow.module.scss';
import { ROUTE_FRANCHISE_MANAGER_WORK_ORDERS } from '../util/constants';
import Badge from './Badge';
import Button from './Button';
import { dayTimeString } from '../util/formatDate';

interface WorkOrderRowProps {
  workOrder: WorkOrder
}

export default function WorkOrderRow({
  workOrder: {
    vehicle,
    lineItems,
    id,
    scheduledAt,
    unavailabilityReason,
    userVehicle,
    user,
    invoice,
    contact,
  },
}: WorkOrderRowProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const serviceName = () => {
    // eslint-disable-next-line max-len
    const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
    const primaryService = lineItems.find(
      (lineItem) => lineItem.service
        && lineItem.service.serviceType.name !== ServiceType.addOn,
    );
    const addOnServices = lineItems.filter(
      (lineItem) => lineItem.service
        && lineItem.service.serviceType.name === ServiceType.addOn,
    );

    const addOnQuantity = addOnServices.length;

    const addOnText = addOnQuantity === 1 ? 'Add-On' : 'Add-Ons';

    const addOnMessage = addOnQuantity > 0
      ? `+ ${addOnQuantity} ${addOnText}`
      : '';

    return `${primaryService?.name} ${addOnMessage} for ${vehicleName}`;
  };

  const setWorkOrderDetails = () => {
    dispatch(setWorkOrderId(id));
    dispatch(setWorkOrderUnavailabilityReason(unavailabilityReason));
    navigate(`${ROUTE_FRANCHISE_MANAGER_WORK_ORDERS}/${id}`);
  };
  return (
    <div className={styles.rowContainer}>
      <div className={styles.descriptionContainer}>
        <div className={styles.mainContent}>
          {!invoice && unavailabilityReason !== 'Available' && (
          <Badge
            variant="primary"
          >
            {unavailabilityReason}
          </Badge>
          )}
          {invoice && (
            <Badge
              variant="primary"
            >
              {invoice.status}
            </Badge>
          )}
          <h4>{serviceName()}</h4>
          {scheduledAt && (
            <div className={styles.subtitle}>
              Scheduled At:
              {' '}
              {dayTimeString(scheduledAt)}
            </div>
          )}
          {userVehicle.vin && (
            <div className={styles.subtitle}>
              VIN:
              {' '}
              {userVehicle.vin}
            </div>
          )}
          {userVehicle.name && (
          <div className={styles.subtitle}>
            Personal Identifier:
            {' '}
            {userVehicle.name}
          </div>
          )}
          {user.name && (
          <div className={styles.subtitle}>
            Customer Name:
            {' '}
            {contact.name}
          </div>
          )}
          {contact.email && (
          <div className={styles.subtitle}>
            Contact Information:
            {' '}
            {contact.email}
            {', '}
            {contact.phoneNumber}
          </div>
          )}
        </div>
        <Button
          className={cx(styles.caret)}
          type="button"
          onClick={setWorkOrderDetails}
          variant="icon"
        >
          <Arrow />
        </Button>
      </div>
    </div>
  );
}

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import serviceTypesReducer from '../features/serviceTypes/serviceTypesSlice';
import postalCodeReducer from '../features/postalCode/postalCodeSlice';
import addOnsReducer from '../features/addOns/addOnsSlice';
import
serviceLocationReducer
  from '../features/serviceLocation/serviceLocationSlice';
import carReducer from '../features/car/carSlice';
import authReducer from '../features/auth/authSlice';
import bookingReducer from '../features/booking/bookingSlice';
import workOrderReducer from '../features/workOrder/workOrderSlice';
import invoiceReducer from '../features/invoice/invoiceSlice';
import customerReducer from '../features/customer/customerSlice';
import franchiseReducer from '../features/franchise/franchiseSlice';

import { loadState } from './localStorage';

export const store = configureStore({
  reducer: {
    serviceTypes: serviceTypesReducer,
    postalCode: postalCodeReducer,
    addOns: addOnsReducer,
    serviceLocation: serviceLocationReducer,
    car: carReducer,
    auth: authReducer,
    booking: bookingReducer,
    workOrder: workOrderReducer,
    invoice: invoiceReducer,
    customer: customerReducer,
    franchise: franchiseReducer,
  },
  preloadedState: loadState(),
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ChangeEvent, FormEvent, useEffect, useState,
} from 'react';
import { useAppSelector } from '../app/hooks';
import { InvoiceProps, PayInvoicePayload } from '../interfaces/Invoice';
import { methods } from '../lang/en/payment.json';
import styles from '../sass/components/PayInvoiceModal.module.scss';
import {
  GET_INVOICE, PAY_BILL,
  PAY_BILL_LATER,
  GET_VENDOR_BY_ID,
} from '../util/gql';
import Button from './Button';
import ConfirmationModal from './ConfirmationModal';
import Field from './Field';
import Modal from './Modal';
import ModalCloseButton from './ModalCloseButton';
import Selector from './Selector';
import TextInput from './TextInput';
import { Vendor } from '../interfaces/Customer';

interface ManualPaymentInvoiceModalProps {
  invoiceDetails?: InvoiceProps
  payInvoiceModal: boolean
  onClose: () => void
  setErrorModal: (status: boolean) => void
}

interface SelectOption {
  value: string;
  label: string;
}

const paymentMethods: SelectOption[] = Object.values(methods).map((method) => ({
  label: method,
  value: method,
}));

const initialValues = {
  paymentMethod: '',
  cardType: '',
  cardFourDigit: '',
  expiryMonth: '',
  expiryYear: '',
  chequeNumber: '',
  transitNumber: '',
  notes: '',
  externalConfirmationNumber: '',
};

export default function PayInvoiceModal(
  {
    invoiceDetails,
    payInvoiceModal,
    onClose,
    setErrorModal,
  }: ManualPaymentInvoiceModalProps,
) {
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const [values, setValues] = useState(initialValues);

  const [payLaterModal, setPayLaterModal] = useState<boolean>(false);
  const [vendorDetails, setVendorDetails] = useState<Vendor>();

  const [payInvoice] = useMutation(PAY_BILL, {
    onCompleted: () => {
      onClose();
    },
    onError: () => {
      onClose();
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleInputChange = (
    e: FormEvent<HTMLInputElement>,
    lengthLimit?: number,
  ): void => {
    const { name, value } = e.currentTarget;
    if (lengthLimit && value.length > lengthLimit) return;
    setValues({ ...values, [name]: value });
  };

  const assembleInvoicePayload = (): PayInvoicePayload => ({
    invoiceId: invoiceDetails?.id,
    userId: invoiceDetails?.userId,
    authorId: currentUser.id,
    franchiseId: invoiceDetails
      ? invoiceDetails.franchiseId : currentUser.franchiseId,
    amount: invoiceDetails?.billedAmount,
    paymentMethod: values.paymentMethod,
    ...values.paymentMethod === methods.cheque
      && { chequeNumber: values.chequeNumber },
    ...values.notes && { notes: values.notes },
  });

  const [payBillLater] = useMutation(PAY_BILL_LATER, {
    onCompleted: () => onClose(),
    onError: () => {
      onClose();
      setPayLaterModal(false);
      setErrorModal(true);
    },
    fetchPolicy: 'network-only',
  });

  const handlePayBillLater = (): void => {
    payBillLater({
      variables: { invoiceId: invoiceDetails?.id },
      refetchQueries: () => [{
        query: GET_INVOICE,
        variables: { invoiceId: invoiceDetails?.id },
      }],
    });
  };

  const [getVendorDetails, { error }] = useLazyQuery(GET_VENDOR_BY_ID, {
    onCompleted: (data: any) => {
      let formattedRemittance;
      if (invoiceDetails?.billedAmount) {
        // eslint-disable-next-line max-len
        formattedRemittance = (data?.getVendorById.remittance / 100) * (invoiceDetails?.billedAmount / 100);
      }

      setVendorDetails({
        vendorId: data?.getVendorById.id,
        name: data?.getVendorById.name,
        remittance: formattedRemittance,
      });
    },
    onError: (err) => {
      console.error('Error fetching vendor details:', err);
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (invoiceDetails?.vendorId) {
      getVendorDetails({ variables: { vendorId: invoiceDetails.vendorId } });
    }
  }, [getVendorDetails, invoiceDetails?.vendorId]);

  const handleSubmit = (): void => {
    if (values.paymentMethod === 'Pay Later') {
      setPayLaterModal(true);
    } else {
      payInvoice(
        {
          variables: assembleInvoicePayload(),
          refetchQueries: () => [{
            query: GET_INVOICE,
            variables: {
              invoiceId: invoiceDetails?.id,
            },
          }],
        },
      );
    }
  };

  if (payLaterModal) {
    return (
      <ConfirmationModal
        message="A payment link will be sent to the customer by e-mail.
        Do you wish to proceed?"
        onSubmit={handlePayBillLater}
        title="Pay invoice later"
        onClose={onClose}
        open={payLaterModal}
      />
    );
  }

  return (
    <Modal
      open={payInvoiceModal}
      onClose={onClose}
      disableBackdropClick
    >
      <div className={styles.modal}>
        <ModalCloseButton onClose={onClose} />
        <div className={styles.body}>
          <h4>Pay Invoice</h4>
          <form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          >
            <Field id="amount" label="Invoiced Amount">
              <TextInput
                value={invoiceDetails?.billedAmountFormatted}
                disabled
              />
            </Field>
            {vendorDetails && (
            <>
              <Field id="vendor-fees" label="Deducted Vendor Fees">
                <TextInput
                  value={vendorDetails.remittance?.toFixed(2)}
                  disabled
                />
              </Field>
              {vendorDetails.remittance && invoiceDetails?.billedAmount && (
                <Field id="vendor-fees" label="Paid Amount">
                  <TextInput
                    value={(
                      (invoiceDetails?.billedAmount / 100
                        - vendorDetails.remittance)
                    ).toFixed(2)}
                    disabled
                  />
                </Field>
              )}
            </>
            )}
            <Field
              id="paymentMethod"
              label="Payment Method - Accepted by Franchisee"
            >
              <Selector
                name="paymentMethod"
                onChange={handleSelectChange}
                value={values.paymentMethod}
                id="paymentMethod"
                label="Payment Method"
                options={paymentMethods}
                isRequired
              />
            </Field>
            <Field id="notes" label="Notes">
              <TextInput
                name="notes"
                onChange={handleInputChange}
                value={values.notes}
                id="notes"
                placeholder="Notes"
              />
            </Field>
            <div className={styles.buttonsContainer}>
              <Button
                className={styles.button}
                onClick={onClose}
                variant="tertiary"
              >
                Cancel
              </Button>
              <Button
                className={styles.button}
                type="submit"
                inactive={!values.paymentMethod}
              >
                Pay
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

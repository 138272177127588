import {
  ApolloError, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import cx from 'classnames';
import pick from 'lodash/pick';
import {
  FormEvent, useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import NumberFormat from 'react-number-format';
import { parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import Button from '../components/Button';
import OrderSummary from '../components/OrderSummary';
import { PasswordInput } from '../components/PasswordInput';
import PreviousSelector, { Selection } from '../components/PreviousSelector';
import TextInput from '../components/TextInput';
import { selectAddOns } from '../features/addOns/addOnsSlice';
import { login } from '../features/auth/authSlice';
import { setBookingSuccess } from '../features/booking/bookingSlice';
import { setPostalCode } from '../features/postalCode/postalCodeSlice';
import {
  Appointment,
  Contact,
  setContact, setDateTime,
  setEmail,
  setName,
  setNewContact,
  setPhone,
} from '../features/serviceLocation/serviceLocationSlice';
import useAuthentication from '../hooks/useAuthentication';
import styles from '../sass/components/Schedule.module.scss';
import {
  ROUTE_BOOK_ADD_ONS,
  ROUTE_BOOK_CONFIRMATION,
} from '../util/constants';
import { getFieldErrors } from '../util/getFieldErrors';
import { selectServiceTypes } from '../features/serviceTypes/serviceTypesSlice';
import Alert from '../components/Alert';
import { authenticate } from './Login';
import {
  CREATE_WORK_ORDER,
  GET_CONTACTS_BY_USER_ID,
  GET_POSTAL,
  GET_VENDOR_LIST,
} from '../util/gql';
import AppointmentDatePicker from '../components/AppointmentDatePicker';
import Select from '../components/EditorSelect';
import { Vendor } from '../interfaces/Customer';

export default function Schedule() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loggedIn: isLoggedIn, handleLogin } = useAuthentication();
  const [password, setPassword] = useState('');
  const [constraints, setConstraints] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const [isGuest, setIsGuest] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [vendor, setVendor] = useState<Vendor | undefined>();
  const [newVendor, setNewVendor] = useState<boolean>(false);
  const [
    isValidAddress,
    setIsValidAddress,
  ] = useState<undefined | boolean>(undefined);

  const {
    selectedAddress,
    selectedContact,
    serviceAddress,
    dateTime,
    contact: {
      email, name, phoneNumber,
    },
  } = useAppSelector((s) => s.serviceLocation);

  const selectedServices = useAppSelector(selectServiceTypes);
  const selectedAddOns = useAppSelector(selectAddOns);

  const currentUser = useAppSelector((
    state,
  ) => state.auth.currentUser);
  const carId = useAppSelector((state) => state.car.id);
  const customerId = useAppSelector((state) => state.customer.id);
  const userVehicleId = useAppSelector((state) => state.car.userVehicleId);
  const vehicleCustomName = useAppSelector((state) => state.car.name);
  const vin = useAppSelector((state) => state.car.vin);
  const franchiseIdSelect = useAppSelector((state) => state.franchise.id);

  const [createWorkOrder, {
    loading: workOrderLoading,
  }] = useMutation(CREATE_WORK_ORDER);

  const {
    data: contactsData,
    loading: contactsLoading,
    error: contactsError,
  } = useQuery(GET_CONTACTS_BY_USER_ID, {
    variables: { userId: customerId || currentUser.id },
    skip: !isLoggedIn,
    fetchPolicy: 'network-only',
  });

  let contacts: Contact[] = useMemo(() => [], []);

  if (contactsData
    && (((currentUser.roles.includes('manager')
    || currentUser.roles?.includes('technician')) && customerId)
    || (!currentUser.roles.includes('manager')
    && !currentUser.roles?.includes('technician')))) {
    contacts = contactsData.getContactsByUserId
      .map((c: Contact) => pick(c, ['name', 'email', 'phoneNumber']));
    if (selectedContact === 'unset' && contacts.length > 0) {
      dispatch(setContact([0, contacts[0]]));
    }
  }

  const contactStrings = contacts
    .map((c) => `${c.name}: ${c.phoneNumber} | ${c.email}`);

  useQuery(GET_POSTAL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const { isInService, code } = data.getPostal;
      if (isInService) {
        dispatch(setPostalCode(code));
      }
      setIsValidAddress(isInService);
    },
    variables: {
      code: serviceAddress?.postalCode,
    },
    skip: !serviceAddress?.postalCode,
  });

  useEffect(() => {
    if (serviceAddress?.postalCode) {
      dispatch(setPostalCode(serviceAddress?.postalCode));
    }
  }, [serviceAddress?.postalCode, dispatch]);

  const [
    getVendorList, {
      data: vendorList,
    },
  ] = useLazyQuery(GET_VENDOR_LIST);

  useEffect(() => {
    if (isVendor) {
      getVendorList();
    }
  }, [getVendorList, isVendor]);

  const handleNextClick = async () => {
    setConstraints([]);
    setErrorCode('');
    setErrorMessage('');
    try {
      await createWorkOrder({
        variables: {
          date: parseISO(dateTime?.date as string),
          vehicleId: carId,
          ...(vehicleCustomName && vehicleCustomName.trim() !== '' && { vehicleCustomName }),
          ...(vin && vin.trim() !== '' && { vin }),
          customerId,
          userVehicleId,
          franchiseIdSelect,
          contactInput: { name, email, phoneNumber },
          addressInput: serviceAddress,
          lineItems: selectedServices.concat(selectedAddOns).map((service) => (
            {
              id: service.id,
              quantity: 1,
            })),
          ...((!isLoggedIn
            || currentUser.roles?.includes('manager')
            || currentUser.roles?.includes('technician'))
          && !customerId
          && !isGuest && { password }),
          ...(isVendor && { vendorListInput: vendor }),
        },
      });
      if (!isLoggedIn && !isGuest) {
        const onLoginSuccess = (token: string) => {
          dispatch(login({ email, token }));
        };
        authenticate(
          { email, password },
          onLoginSuccess,
          (message: string) => setErrorMessage(message),
          (message: any) => setConstraints(message),
        );
      }

      dispatch(setBookingSuccess(true));
      navigate(ROUTE_BOOK_CONFIRMATION);
    } catch (ex) {
      if (ex instanceof ApolloError) {
        const {
          message,
          statusCode,
          error,
        } = ex.graphQLErrors[0].extensions.response;
        if (statusCode === 422) {
          setConstraints(message);
        }
        if (statusCode === 400) {
          setErrorMessage(message);
          setErrorCode(error);
        }
      }
    }
  };

  const handleSetDateTime = useCallback((date: Appointment) => {
    dispatch(setDateTime(date));
  }, [dispatch]);

  const hasPasswordOrIsGuestOrIsLoggedIn = !!password || isGuest || isLoggedIn;

  const newContactValid = email && name && phoneNumber;
  const contactValid = (selectedContact !== 'new'
  && selectedContact !== 'unset')
    || newContactValid;
  const addressValid = (selectedAddress !== 'new'
  && selectedAddress !== 'unset')
    || isValidAddress;
  const shouldEnableNext = contactValid
    && addressValid
    && dateTime
    && hasPasswordOrIsGuestOrIsLoggedIn;

  const onChangeContactSelector = useCallback((s: Selection) => {
    if (s === 'new' || s === 'unset') {
      dispatch(setNewContact());
    } else {
      dispatch(setContact([s, contacts[s]]));
    }
  }, [dispatch, contacts]);

  const handleChangeVendor = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const vendorSelect = vendorList.getVendors.find(
      (v: Vendor) => v.name === event.target.value,
    );
    setVendor(() => ({
      vendorId: vendorSelect.id,
      name: vendorSelect.name,
      remittance: vendorSelect.remittance,
    }));
  };

  // eslint-disable-next-line max-len
  const handleRemittanceChange = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setVendor((prevVendor) => ({
      ...prevVendor,
      remittance: Number(target.value),
    } as Vendor));
  };

  // eslint-disable-next-line max-len
  const handleNewVendorChange = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    setVendor((prevVendor) => ({
      ...prevVendor,
      name: target.value,
    } as Vendor));
  };

  return (
    <div className={styles.schedule}>
      <div className={styles.header}>
        <h1 className={styles.headerDesktop}>Schedule And Book</h1>
        <h4 className={styles.headerMobile}>Schedule And Book</h4>
        <Button
          variant="secondary"
          onClick={handleLogin}
        >
          {isLoggedIn ? 'Log Out' : 'Log In'}
        </Button>
      </div>
      <div className={cx(styles.body, 'container--fluid')}>
        <div className={cx(styles.bodyLeft, 'container__col-xs-8')}>
          <div className={styles.serviceLocationForm}>
            <form>
              <div className={styles.formHeader}>
                <h4>Schedule Your Service</h4>
                <p>
                  Provide a time for the appointment.
                </p>
              </div>
              <div className={cx(styles.formSection)}>

                <AppointmentDatePicker
                  onSelect={handleSetDateTime}
                  value={dateTime}
                  timeNoLongerAvailable={errorCode === 'TIME_SLOT_UNAVAILABLE'}
                />
              </div>

              <div className={styles.formHeader}>
                <h4>Contact Information</h4>
                <p>
                  Select a primary contact for this booking.
                  We will never share contact information with anyone else.
                </p>
                <PreviousSelector
                  data={contactStrings}
                  newLabel="Add another contact"
                  loading={contactsLoading}
                  error={!!contactsError}
                  name="contact"
                  selected={selectedContact}
                  onChange={onChangeContactSelector}
                  childClassName={styles.formSubsection}
                >

                  <TextInput
                    id="name"
                    value={name}
                    title="Name"
                    className={styles.input}
                    onChange={
                      (event: FormEvent<HTMLInputElement>) => (
                        dispatch(setName(event.currentTarget.value)))
                    }
                  />

                  <NumberFormat
                    id="phone"
                    title="Phone Number"
                    className={styles.input}
                    errorMessage={
                      getFieldErrors(constraints, ['contact', 'phoneNumber'])
                    }
                    value={phoneNumber}
                    onChange={
                      (event: FormEvent<HTMLInputElement>) => (
                        dispatch(setPhone(event.currentTarget.value)))
                    }
                    format="(###) ###-####"
                    mask="_"
                    allowEmptyFormatting
                    customInput={TextInput}
                  />
                  <div className={styles.passwordBox}>
                    <TextInput
                      id="email"
                      value={email}
                      title="Email"
                      className={styles.input}
                      errorMessage={
                      getFieldErrors(constraints, ['contact', 'email'])
                    }
                      onChange={
                      (event: FormEvent<HTMLInputElement>) => (
                        dispatch(setEmail(event.currentTarget.value)))
                    }
                      hint="The customer will receive a
                          confirmation at this email address."
                    />
                    {(currentUser.roles?.includes('manager')
                    || currentUser.roles?.includes('technician'))
                    && !customerId && (
                      <label className={styles.bookAsVendor} htmlFor="vendor">
                        <input
                          id="vendor"
                          type="checkbox"
                          checked={isVendor}
                          onChange={() => setIsVendor(!isVendor)}
                        />
                        <span>Book appointment for vendor</span>
                      </label>
                    )}
                  </div>

                  {(!isLoggedIn
                  || currentUser.roles?.includes('manager')
                  || currentUser.roles?.includes('technician'))
                  && !customerId && (
                    <div className={styles.passwordBox}>
                      <PasswordInput
                        value={password}
                        placeholder="Password"
                        className={styles.input}
                        onChange={
                          (event) => setPassword(event.currentTarget.value)
                        }
                        title="Password"
                        errorMessage={getFieldErrors(constraints, ['password'])}
                        hint={'Log in with this password '
                          + 'anytime to manage your bookings.'}
                        disabled={isGuest}
                      />
                      <label className={styles.bookAsGuest} htmlFor="guest">
                        <input
                          id="guest"
                          type="checkbox"
                          checked={isGuest}
                          onChange={() => setIsGuest(!isGuest)}
                        />
                        <span>Book appointment as guest</span>
                      </label>
                    </div>
                  )}
                  { vendorList && (
                    <div className={styles.containerNewVendor}>
                      <br />
                      <p>
                        Select a fleet or add a new one if the customer`s
                        fleet is not listed in the dropdown menu.
                      </p>
                    </div>
                  )}
                  { vendorList && !newVendor && (
                    <div className={styles.container}>
                      <Select
                        label="Select fleet from list"
                        loading={false}
                        id="vendor-input"
                        placeholder="Select Fleet"
                        value={vendor?.name?.toString() || ''}
                        onChange={handleChangeVendor}
                      >
                        {vendorList.getVendors.map((vendorSelect: Vendor) => (
                          <option
                            key={vendorSelect.vendorId}
                            value={vendorSelect?.vendorId?.toString()}
                          >
                            {vendorSelect?.name}
                          </option>
                        ))}
                      </Select>
                      <TextInput
                        className={styles.individualItem}
                        title="remittance fee %"
                        placeholder="fee"
                        type="number"
                        value={vendor?.remittance
                          ? vendor.remittance.toString() : ''}
                        min={0}
                      />

                    </div>
                  )}
                  { vendorList && (
                    <div className={styles.containerNewVendor}>
                      <br />
                      <Button
                        onClick={() => {
                          setVendor(({
                            vendorId: 0,
                            name: '',
                            remittance: 0,
                          }));
                          setNewVendor((prevState) => !prevState);
                        }}
                        variant="primary"
                        size="small"
                        className={styles.addButton}
                      >
                        {newVendor ? 'Select vendor from list' : 'New Vendor'}
                      </Button>
                    </div>

                  )}
                  {newVendor && (
                  <div className={styles.container}>
                    <TextInput
                      className={styles.individualItemName}
                      title="Fleet Name"
                      placeholder="Enter fleet name"
                      type="text"
                      value={vendor?.name}
                      onChange={handleNewVendorChange}
                    />

                    <TextInput
                      className={styles.individualItem}
                      title="Remittance Fee %"
                      placeholder="fee"
                      type="number"
                      value={vendor?.remittance?.toString()}
                      min={0}
                      name="remittance"
                      onChange={handleRemittanceChange}
                    />
                  </div>

                  )}
                </PreviousSelector>

              </div>

              <Alert
                message={errorCode === 'TIME_SLOT_UNAVAILABLE'
                  ? ''
                  : errorMessage}
                type="error"
              />
            </form>
          </div>
        </div>
        <div className={cx(
          styles.bodyRight,
          'container__col-xs-4',
        )}
        >
          <OrderSummary />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          onClick={() => navigate(ROUTE_BOOK_ADD_ONS)}
          variant="tertiary"
          className={styles.selectButton}
        >
          Back
        </Button>
        <Button
          variant="primary"
          className={styles.selectButton}
          onClick={handleNextClick}
          inactive={!shouldEnableNext}
          loading={workOrderLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

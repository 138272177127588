import { gql } from '@apollo/client';

export const GET_POSTAL = gql`
  query GetPostal($code: String!) {
    getPostal(input: { code: $code }) {
      isInService
      code
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      user {
        id
        name
        email
        notificationRequests
      }
      customerVendor {
        id
        externalCustomerVendorId
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($userId: Int!) {
    getUserById(userId:$userId) {
        id
        email
        name
        discountType
        discountAmount
    }
  }
`;

export const GET_YEARS = gql`
  query getAllVehicleYears {
    getAllVehicleYears {
      year
    }
  }
`;

export const GET_MAKES_BY_YEAR = gql`
  query GetMakesByYear($year: String!) {
    getMakesByYear(year: $year) {
      make
    }
  }
`;

export const GET_MODELS_BY_YEAR_AND_MAKE = gql`
  query GetModelsByYearAndMake($year: String!, $make: String!) {
    getModelsByYearAndMake(year: $year, make: $make) {
      model
    }
  }
`;

export const GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR = gql`
  query GetVehiclesByMakeModelAndYear(
    $make: String!
    $model: String!
    $year: Int!
  ) {
    getVehiclesByMakeModelAndYear(make: $make, model: $model, year: $year) {
      id
      make
      model
      year
      engine
    }
  }
`;

export const GET_VEHICLES_BY_MAKE_MODEL_AND_YEAR_AND_ENGINE = gql`
  query getVehiclesByMakeModelAndYearAndEngine(
    $make: String!
    $model: String!
    $year: Int!
    $engine: String!
  ) {
    getVehiclesByMakeModelAndYearAndEngine(
      make: $make,
      model: $model,
      year: $year,
      engine: $engine
    ) {
      id
      make
      model
      year
      engine
    }
  }
`;

export const GET_SERVICE_PRICES = gql`
  query GetServicePrices(
    $vehicleId: Int! 
    $countryCode: String, 
    $customerId: Int
    ) {
    getServicePrices(
      vehicleId: $vehicleId, 
      countryCode: $countryCode, 
      customerId: $customerId
    ) {
      id
      name
      description
      value
      sortOrder
      taxName
      taxPercent
      taxId
      discountedValue
    }
  }
`;

export const GET_ADD_ON_PRICES = gql`
  query GetAddOnPrices($vehicleId: Int!, $customerId: Int) {
    getAddOnPrices (vehicleId: $vehicleId, customerId: $customerId) {
      id
      name
      value
      description
      sortOrder
      taxName
      taxPercent
      taxId
      discountedValue
    }
  }
`;

export const GET_ALL_SERVICES = gql`
  query GetAllServices($workOrderId: Int!) {
    getAllServices(workOrderId: $workOrderId){
      id
      name
      description
      value
    }
  }
`;

export const GET_VEHICLES = gql`
  query GetVehicles {
    getVehicles {
      id
      make
      model
      year
      engine
    }
  }
`;

export const GET_ADDRESSES = gql`
  query GetAddresses {
    getAddresses {
      id
      street
      city
      postalCode
      lat
      lng
      province
      country
      countryCode
      provinceCode
    }
  }
`;

export const GET_ADDRESSES_BY_USER_ID = gql`
  query GetAddressesByUserId ($userId: Int!) {
    getAddressesByUserId (userId: $userId) {
      id
      street
      city
      postalCode
      lat
      lng
      province
      country
      countryCode
      provinceCode      
    }
  }
`;

export const GET_CONTACTS = gql`
  query GetContacts{
    getContacts{
      id
      name
      email
      phoneNumber
    }
  }
`;

export const GET_VENDOR_LIST = gql`
  query GetVendors{
      getVendors{
        id
        name
        remittance
      }
    }
`;
export const GET_VENDOR_BY_ID = gql`
  query GetVendorById ($vendorId: Int!){
      getVendorById(vendorId: $vendorId){
        id
        name
        remittance
      }
    }
`;

export const GET_CONTACTS_BY_USER_ID = gql`
  query GetContactsByUserId ($userId: Int!){
    getContactsByUserId (userId: $userId){
      id
      name
      email
      phoneNumber
    }
  }
`;

export const GET_AVAILABILITY = gql`
  query getAvailability(
    $start: DateTime!
    $end: DateTime!
    $postalCode: GetPostalInput!
    $userTimezone: String!
  ) {
    getAvailability(
      start: $start,
      end: $end,
      postalCode: $postalCode,
      userTimezone: $userTimezone,
    ) {
      date
      routeId
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact(
    $name: String!
    $email: String!
    $phoneNumber: String!
  ) {
    createContact(
      input: { name: $name, email: $email, phoneNumber: $phoneNumber }
    ) {
      id
    }
  }
`;

export const UPDATE_EMAIL_PREFERENCE = gql`
  mutation UpdateEmailPreference($checkedEmail: Boolean!) {
    updateEmailPreference(checkedEmail: $checkedEmail)
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact(
    $name: String!
    $email: String!
    $phoneNumber: String!
    $id: Int!
  ) {
    updateContact(
      input: { name: $name, email: $email, phoneNumber: $phoneNumber }
      contactId: $id
    ) {
      id
      name
      email
      phoneNumber
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation DeleteContact($id: Int!) {
    deleteContact(contactId: $id) {
      id
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation CreateAddress(
    $street: String!
    $city: String!
    $postalCode: String!
    $province: String!
    $country: String!
    $countryCode: String!
    $provinceCode: String!
    $lat: Float!
    $lng: Float!
  ) {
    createAddress(
      input: {
        street: $street
        city: $city
        postalCode: $postalCode
        lat: $lat
        lng: $lng
        province: $province
        country: $country
        countryCode: $countryCode
        provinceCode: $provinceCode
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress(
    $street: String!
    $city: String!
    $postalCode: String!
    $province: String!
    $country: String!
    $countryCode: String!
    $provinceCode: String!
    $lat: Float!
    $lng: Float!
    $id: Int!
  ) {
    updateAddress(
      input: {
        street: $street
        city: $city
        postalCode: $postalCode
        lat: $lat
        lng: $lng
        province: $province
        country: $country
        countryCode: $countryCode
        provinceCode: $provinceCode
      }
      addressId: $id
    ) {
      id
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: Int!) {
    deleteAddress(addressId: $id) {
      id
    }
  }
`;

export const ADD_VEHICLE = gql`
  mutation AddVehicle($vehicleId: Int!) {
    addVehicle(vehicleId: $vehicleId) {
      id
    }
  }
`;

export const REMOVE_VEHICLE = gql`
  mutation RemoveVehicle($vehicleId: Int!) {
    removeVehicle(vehicleId: $vehicleId) {
      id
    }
  }
`;

export const DELETE_USER_VEHICLE = gql`
  mutation DeleteUserVehicle($id: Int!) {
    deleteUserVehicle(id: $id) {
      id
    }
  }
`;
export const IS_WORK_ORDER_ASSIGNED_TO_USER_VEHICLE = gql`
  mutation IsUserVehicleAssignedToWorkOrder($id: Int!) {
    isUserVehicleAssignedToWorkOrder(id: $id)
  }
`;

export const GET_WORK_ORDERS = gql`
  query GetWorkOrders($status: String!) {
    getWorkOrders(status: $status) {
      id
      vehicle {
        make
        model
        year
      }
      lineItems {
        id
        price
        tax
        quantity
        name
        description
        discountedPrice
      }
      scheduledAt
    }
  }
`;

export const CANCEL_WORK_ORDER = gql`
  mutation CancelWorkOrder(
    $workOrderId: Int!,
    $cancelReason: String!,
    $proofOfCancelLocation: String,
    ) {
    cancelWorkOrder(
      workOrderId: $workOrderId,
      cancelReason: $cancelReason,
      proofOfCancelLocation: $proofOfCancelLocation,
    )
    {
      id
    }
  }
`;

export const DELETE_WORK_ORDER = gql`
  mutation DeleteWorkOrder($workOrderId: Int!) {
    deleteWorkOrder(workOrderId: $workOrderId)
  }
`;

export const ASSIGN_WORK_ORDER = gql`
mutation AssignWorkOrder(
  $workOrderId: Int!,
  $assignedFranchise: Int!
  ) {
  assignWorkOrder(
    workOrderId: $workOrderId
    assignedFranchise: $assignedFranchise
    )
}
`;

export const GET_FRANCHISES_OF_FRANCHISE_MANAGER = gql`
  query getFranchisesOfFranchiseManager{
    getFranchisesOfFranchiseManager{
      id
      name
    }
  }
`;

export const GET_CUSTOMERS_OF_FRANCHISE_MANAGER = gql`
  query GetCustomersOfFranchiseManager ($franchiseId: Int!){
    getCustomersOfFranchiseManager (franchiseId: $franchiseId) {
      id
      name
      email
    }
  }
`;

export const GET_PAYOUT_REPORTS_FRANCHISE_MANAGER = gql`
  query GetPayoutStatementsFranchiseManager(
    $franchiseId: Int!
    $pageNumber: Int!
    ){
    getPayoutStatementsFranchiseManager(
      franchiseId: $franchiseId
      pageNumber: $pageNumber,
      ) {
      payoutReports {
        id,
        franchiseId,
        startDate,
        endDate,
        invoicedAmount,
        totalPayments,
        transactionFees,
        totalTax,
        marketingFee,
        royaltyFee,
        insurance,
        technologyFee,
        thirdPartyFleetFee,
        nationalFleetFee,
        creditGoOil,
        refunds,
        cashPayments,
        total
      }
      total
    }
  }
`;

export const GET_PAYOUT_REPORT_FRANCHISE_MANAGER_BY_ID = gql`
  query GetPayoutReportFranchiseManagerById(
    $id: Int!
    ){
    getPayoutReportFranchiseManagerById(
      id: $id
      ) {
        id
        franchiseId
        startDate
        endDate
        invoicedAmount
        totalPayments
        transactionFees
        totalTax
        marketingFee
        royaltyFee
        insurance
        technologyFee
        thirdPartyFleetFee
        nationalFleetFee
        creditGoOil
        refunds
        cashPayments
        total
        status
        franchise {
          id
          name
          address {
            countryCode
          }
      }
    }
  }
`;

export const GET_PAYOUT_REPORT_PAYMENTS = gql`
query GetPayoutReportPayments(
  $id: Int!
  $startDate: DateTime!
  $endDate: DateTime!
  $franchiseId: Int!
  ){
  getPayoutReportPayments(
    id: $id
    startDate: $startDate
    endDate: $endDate
    franchiseId: $franchiseId
    ) {
      paymentDetails{
        id
        amount
        paymentMethod
        status
        remittanceFee
        paidAmount
        createdAt
        franchise{
          id
          name
        }
        author{
          id
          name
        }
        invoice{
          id
          createdAt
        }
      }
    }
  }
`;
export const GET_PAYOUT_REPORT_INVOICES = gql`
query GetPayoutReportInvoices(
  $id: Int!
  $startDate: DateTime!
  $endDate: DateTime!
  $franchiseId: Int!
  ){
  getPayoutReportInvoices(
    id: $id
    startDate: $startDate
    endDate: $endDate
    franchiseId: $franchiseId
    ) {
      invoiceDetails{
        id
        status
        tax
        subTotal
        billedAmount
        createdAt
        contact{
          name
        }
        workOrder{
          id
          status
          completedAt
          poNumber
        }
        franchise{
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UpdateUserInfo($name: String, $email: String, $password: String) {
    updateUserInfo(input: { name: $name, email: $email, password: $password }) {
      name
      email
    }
  }
`;

export const CONFIRM_WORK_ORDER = gql`
query ConfirmWorkOrder( $id: Int!) {
  confirmWorkOrder(id: $id) {
    id
  }
}
`;

export const UPDATE_WORK_ORDER_VEHICLE_ID = gql`
  mutation UpdateWorkOrderVehicleId($id: Int!, $vehicleId: Int!) {
    UpdateWorkOrderVehicleId(id: $id, vehicleId: $vehicleId ) {
      id
    }
  }
`;

export const UPDATE_LINE_ITEMS = gql`
mutation UpdateLineItems(
  $serviceIds: [ServiceItemInput!]!, 
  $id: Int!
  $poNumber: String!,
  ) {
  updateLineItems(
    input:{
      serviceIds: $serviceIds
      id: $id,
      poNumber: $poNumber
    }
    ) {
    id
  }
}
`;

export const UPDATE_LINE_ITEMS_PRICES = gql`
mutation UpdateLineItemsPrices($id: Int!) {
    updateLineItemsPrices(id: $id) {
      id
    }
  }
`;

export const UPDATE_LINE_ITEM_LOCATION = gql`
mutation UpdateLineItemLocation(
  $proofOfFixLocation: [ProofOfFixInput!]!, 
  $id: Int!
  ) {
    updateLineItemLocation(
    input:{
      proofOfFixLocation: $proofOfFixLocation
      id: $id,
    }
    ) {
    id
  }
}
`;

export const CREATE_WORK_ORDER = gql`
  mutation CreateWorkOrder(
    $date: DateTime!
    $password: String
    $customerId: Int
    $vehicleId: Int!
    $userVehicleId: Int
    $franchiseIdSelect: Int
    $contactInput: ContactInput!
    $addressInput: AddressInput!
    $lineItems: [LineItemInput!]!
    $vendorListInput: VendorListInput
    $vehicleCustomName: String
    $vin: String
  ) {
    createWorkOrder(
      input: {
        date: $date
        password: $password
        customerId: $customerId
        vehicleId: $vehicleId
        userVehicleId: $userVehicleId
        franchiseIdSelect: $franchiseIdSelect
        contact: $contactInput
        address: $addressInput
        lineItems: $lineItems
        vendor: $vendorListInput
        vehicleCustomName: $vehicleCustomName
        vin: $vin
      }
    ) {
      id
    }
  }
`;

export const CREATE_BOOKING_ASSISTANCE_NOTIFICATION = gql`
  mutation CreateBookingAssistanceNotification(
    $name: String!
    $phoneNumber: String!
    $email: String!
    $body: String!
    $postalCode: String!
    $recaptchaToken: String!
  ) {
    createBookingAssistanceNotification(
      input: {
        message: {
          name: $name
          phoneNumber: $phoneNumber
          email: $email
          body: $body
          postalCode: $postalCode
        }
        recaptchaToken: $recaptchaToken
      }
    ) {
      name
      phoneNumber
      email
      postalCode
      body
    }
  }
`;

export const GET_FRANCHISEE_WORK_ORDERS = gql`
  query GetFranchiseeWorkOrders(
    $franchiseId: Int! 
    $status: String!
    $pageNumber: Int!
    $filter: String
  ) {
    getFranchiseeWorkOrders(
      franchiseId: $franchiseId,  
      status: $status, 
      pageNumber: $pageNumber,
      filter: $filter,
    ) {
      workOrderWithReason {
        workOrder {
          id
          vehicle {
            make
            model
            year
            engine
          }
          userVehicle{
            id
            vin
            name
          }
          invoice {
            status
            billedAmount
            id
          }
          lineItems {
            id
            name
            description
            price
            tax
            service {
                serviceType {
                    name
                }
            }
          }
          user{
            id
            name
            email
          }
          contact{
            id
            name
            email
            phoneNumber
          }
          scheduledAt
        }
        reason
      }
      total
    }
  }
`;

export const GET_WORK_ORDER = gql`
  query GetWorkOrderById($id: Int!) {
    getWorkOrderById(id: $id) {
      id
      isInspected
      isConfirmed
      poNumber
      address {
        street
        city
        countryCode
        postalCode
        province
      }
      contact {
        name,
        email, 
        phoneNumber
      }
      vehicle {
        id
        year
        make
        model
        engine
        oilVolume
        recommendedOil {
          type
          weight
        }
      }
      userVehicle {
          id
          userId
          vin
          name
          vehicleId
          subscriptions {
            id
            status
            subscriptionTypeId
          }
      }
      franchise {
        id
        name
        managerEmail
        phoneNumber
      }
      scheduledAt
      notes
      user {
        id
        email
        discountAmount
        discountType
      }
      status
      invoice {
        status
        billedAmount
        id
      }
      lineItems {
        id
        name
        price
        tax
        discountedPrice
        service {
          id
            serviceType {
                name
            }
        }
      }
    }
  }
`;

export const UPDATE_WORK_ORDER_PO_NUMBER = gql`
  mutation UpdateWorkOrderPoNumber($id: Int!, $poNumber: String!) {
    updateWorkOrderPoNumber(id: $id, poNumber: $poNumber ) {
      id
      poNumber
    }
  }
`;

export const UPDATE_WORK_ORDER_NOTES = gql`
  mutation UpdateWorkOrderNotes($id: Int!, $notes: String!) {
    updateWorkOrderNotes(id: $id, notes: $notes ) {
      id
      notes
    }
  }
`;

export const UPDATE_SCHEDULED_TIME = gql`
  mutation UpdateScheduledTime($id: Int!, $scheduledAt: DateTime!) {
    updateScheduledTime(id: $id, scheduledAt: $scheduledAt) {
      workOrder {
        id
      }
      reason
    }
  }
`;

export const GET_UNAVAILABILITY_REASON = gql`
  query GetUnavailabilityReason($id: Int!) {
    getUnavailabilityReason(id: $id) {
      workOrder {
        id
      }
      reason
    }
  }
`;

export const BILL_WORK_ORDER = gql`
  mutation BillWorkOrder(
    $workOrderId:Int!, 
    $discountAmount:Int, 
    $discountType:String
    $subscriptionTypeId:Int
    $poNumber:String
  ) {
    billWorkOrder(
      workOrderId:$workOrderId, 
      discountAmount:$discountAmount, 
      discountType:$discountType
      subscriptionTypeId:$subscriptionTypeId 
      poNumber:$poNumber
    ) {
      invoiceId
    }
  }
`;

export const CREATE_MILEAGE = gql`
  mutation CreateMileage(
    $userVehicleId:Int!, 
    $mileage:Int!,
    $workOrderId: Int!,
    $eventType:String
  ) {
    createMileage(
      userVehicleId:$userVehicleId,
      mileage:$mileage,
      workOrderId:$workOrderId,
      eventType:$eventType
    ) {
      id
    }
  }
`;

export const GET_PRESIGNED_URL = gql`
  mutation GetPresignedUrl(  
    $fileName:String!, 
  ) {
    getPresignedUrl(
      fileName:$fileName,
    ) {
      id,
      presignedUrl,
    }
  }
`;

export const CREATE_AWS_PATH = gql`
  mutation CreateAwsPath(
    $id:Int!, 
    $vinLocation:String!,
    $mileageLocation:String!,
    $vehicleLocation:String!,
    $oilCapLocation:String!,
    $vehicleLocationFrontLeft: String!,
    $vehicleLocationFrontRight: String!,
    $vehicleLocationRearLeft: String!,
    $vehicleLocationRearRight: String!,
    $tinLocationFrontLeft: String!,
    $tinLocationFrontRight: String!,
    $tinLocationRearLeft: String!,
    $tinLocationRearRight: String!,
    $oilFilterLocation: String!,
  ) {
    createAwsPath(
      id:$id,
      vinLocation:$vinLocation,
      mileageLocation:$mileageLocation,
      vehicleLocation:$vehicleLocation,
      oilCapLocation:$oilCapLocation,
      vehicleLocationFrontLeft:$vehicleLocationFrontLeft,
      vehicleLocationFrontRight:$vehicleLocationFrontRight,
      vehicleLocationRearLeft:$vehicleLocationRearLeft,
      vehicleLocationRearRight:$vehicleLocationRearRight,
      tinLocationFrontLeft:$tinLocationFrontLeft,
      tinLocationFrontRight:$tinLocationFrontRight,
      tinLocationRearLeft:$tinLocationRearLeft,
      tinLocationRearRight:$tinLocationRearRight,
      oilFilterLocation:$oilFilterLocation,
    ) {
      id
    }
  }
`;

export const UPDATE_USER_VEHICLE_VIN_WITH_ID = gql`
  mutation UpdateUserVehicleVinWithId($userVehicleId:Int!, $vin:String!) {
    updateUserVehicleVinById (
      userVehicleId:$userVehicleId
      vin:$vin
    ) {
      id
    }
  }
`;

export const UPDATE_USER_VEHICLE_NAME_WITH_ID = gql`
  mutation UpdateUserVehicleNameWithId($userVehicleId:Int!, $name:String!) {
    updateUserVehicleNameById (
      userVehicleId:$userVehicleId
      name:$name
    ) {
      id
    }
  }
`;

export const SET_USER_DEFAULT_DISCOUNT = gql`
  mutation SetUserDefaultDiscount(
    $userId:Int!, 
    $discountAmount:String!, 
    $discountType:String!
  ) {
    setUserDefaultDiscount(
      userId:$userId
      discountAmount:$discountAmount
      discountType:$discountType
    ) {
      success
    }
  }
`;

export const REMOVE_USER_DEFAULT_DISCOUNT = gql`
  mutation RemoveUserDefaultDiscount($userId:Int!) {
    removeUserDefaultDiscount(
      userId:$userId
    ) {
      success
    }
  }
`;

export const FIND_ALL_USER_VEHICLES_BY_CURRENT_USER = gql`
  query FindAllUserVehiclesByCurrentUser {
    findAllUserVehiclesByCurrentUser {
      vehicle {
        id
        make 
        model
        year
      }
      id
      vin
      name
      mileage {
        mileage
      }
      subscriptions {
        id
        status
        userVehicleId
      }
    }
  }
`;

export const FIND_ALL_USER_VEHICLES_BY_USER_ID = gql`
  query FindAllUserVehiclesByUserId ($userId: Int!) {
    findAllUserVehiclesByUserId (userId: $userId) {
      vehicle {
        id
        make 
        model
        year
      }
      id
      vin
      name
    }
  }
`;

export const FIND_OR_CREATE_STRIPE_CUSTOMER = gql`
  mutation FindOrCreateStripeCustomer(
    $customerVendorInput: CustomerVendorInput!
    $userId: Int!
    ) {
      findOrCreateStripeCustomer(
        customerVendorInput: $customerVendorInput
        userId: $userId
      ) {
        id
        userId
        externalCustomerVendorId
        vendorName
      }
    }
`;

export const FIND_CUSTOMER_VENDOR = gql`
query findCustomerVendor(
  $userId: Int!
  ) {
    findCustomerVendor(
      userId: $userId
    ) {
        id
        externalCustomerVendorId
    }
  }
`;

export const CREATE_PAYMENT_METHOD_VENDOR = gql`
  mutation CreatePaymentMethodVendor(
    $paymentMethodVendorInput: PaymentMethodVendorInput!
    $userId: Int!
  ) {
  createPaymentMethodVendor(
    paymentMethodVendorInput: $paymentMethodVendorInput
    userId: $userId
  ) {
    id
    externalPaymentMethodId
    }
  }
`;

export const GET_CUSTOMER_PAYMENT_METHODS = gql`
  query getCustomerPaymentMethods(
    $customerVendorId: String!
    $countryAccount: String!
    ) {
      getCustomerPaymentMethods(
        customerVendorId: $customerVendorId
        countryAccount: $countryAccount
      ) {
        expYear
        last4
        brand
      }
    }
`;

export const GET_INVOICE = gql`
  query GetInvoiceById($invoiceId: Int!) {
    getInvoiceById(invoiceId: $invoiceId) {
      id
      user {
        email
        id
        vendorId
      }
      contact {
        name
      }
      address {
        street
        city
        countryCode
      }
      franchise {
        name
        id
      }
      status
      tax
      billedAmount
      workOrder {
        id
      }
      subTotal
      discountSubTotal
      discountAmount
    }
  }
`;

export const PAY_BILL = gql`
  mutation PayBill(
    $invoiceId: Int!
    $userId: Int!
    $authorId: Int!
    $franchiseId: Int!
    $amount: Int!
    $paymentMethod: String!
    $chequeNumber: String
    $transitNumber: String
    $cardType: String
    $cardFourDigit: String
    $expiryMonth: String
    $expiryYear: String
    $notes: String
    $externalConfirmationNumber: String
  ) {
    payBill(
      invoiceId: $invoiceId
      input: {
        userId: $userId
        authorId: $authorId
        franchiseId: $franchiseId
        amount: $amount
        paymentMethod: $paymentMethod
        chequeNumber: $chequeNumber
        transitNumber: $transitNumber
        cardType: $cardType
        cardFourDigit: $cardFourDigit
        expiryMonth: $expiryMonth
        expiryYear: $expiryYear
        notes: $notes
        externalConfirmationNumber: $externalConfirmationNumber
      }
    ) {
      paymentId
    }
  }
`;

export const GET_READERS_BY_FRANCHISE = gql`
  query ListReadersByFranchiseId($franchiseId: Int!) {
    listReadersByFranchiseId(franchiseId: $franchiseId) {
      id,
      label,
      serialNumber
    }
  }
`;

export const SEND_PAYMENT_TO_TERMINAL = gql`
  mutation SendPaymentToTerminal($invoiceId:Int!, $readerId:String!) {
    sendPaymentToTerminal(invoiceId: $invoiceId, readerId:$readerId) {
      paymentIntentId
    }
  }
`;

export const CANCEL_TERMINAL_PAYMENT = gql`
  mutation CancelTerminalPayment($readerId:String!, $invoiceId: Int!) {
    cancelTerminalPayment(readerId: $readerId, invoiceId: $invoiceId) {
      id
    }
  }
`;

export const PAY_CASH_APP = gql`
  mutation PayCashApp($invoiceId:Int!) {
    payCashApp(invoiceId: $invoiceId) {
      paymentId,
      label,
    }
  }
`;

export const PAY_BILL_LATER = gql`
  mutation PayBillLater($invoiceId:Int!) {
    payBillLater(invoiceId: $invoiceId)
  }
`;

export const PAY_PAYOUT_REPORT = gql`
mutation PayPayoutReport($payoutReportId:Int!) {
  payPayoutReport(payoutReportId: $payoutReportId){
    checkOutId
  }
}
`;

export const CREATE_INSPECTION = gql`
  mutation CreateInspection(
    $workOrderId: Int!,
    $userVehicleId:Int!, 
    $tireConditionFrontLeft: Int, 
    $tireConditionFrontRight: Int, 
    $tireConditionRearLeft: Int, 
    $tireConditionRearRight: Int, 
    $brakeConditionFrontLeft: Int, 
    $brakeConditionFrontRight: Int, 
    $brakeConditionRearLeft: Int, 
    $brakeConditionRearRight: Int, 
    $windshieldsCondition: Int, 
    $tireSize: Int,
    $engineHours: Int,
    $notes: String
    $tireIdNumberFrontLeft: Int, 
    $tireIdNumberFrontRight: Int,
    $tireIdNumberRearLeft: Int,
    $tireIdNumberRearRight: Int,
    $checkedFrontLeft: Boolean,
    $checkedFrontRight: Boolean,
    $checkedRearLeft: Boolean,
    $checkedRearRight: Boolean,
    $windshieldFluid: Boolean,
    $brakeFluid: Boolean,
    $leakInspection: Boolean,
    $oilFilterId: String,
    $cabinFilterId: String,
    $engineFilterId: String,
  ) {
    createInspection(
      workOrderId: $workOrderId,
      userVehicleId: $userVehicleId,
      tireConditionFrontLeft: $tireConditionFrontLeft,
      tireConditionFrontRight: $tireConditionFrontRight,
      tireConditionRearLeft: $tireConditionRearLeft,
      tireConditionRearRight: $tireConditionRearRight,
      brakeConditionFrontLeft: $brakeConditionFrontLeft,
      brakeConditionFrontRight: $brakeConditionFrontRight,
      brakeConditionRearLeft: $brakeConditionRearLeft,
      brakeConditionRearRight: $brakeConditionRearRight,
      windshieldsCondition: $windshieldsCondition,
      tireSize: $tireSize,
      engineHours: $engineHours,
      notes: $notes
      tireIdNumberFrontLeft: $tireIdNumberFrontLeft,
      tireIdNumberFrontRight: $tireIdNumberFrontRight,
      tireIdNumberRearLeft: $tireIdNumberRearLeft,
      tireIdNumberRearRight: $tireIdNumberRearRight,
      checkedFrontLeft: $checkedFrontLeft,
      checkedFrontRight: $checkedFrontRight,
      checkedRearLeft: $checkedRearLeft,
      checkedRearRight: $checkedRearRight,
      windshieldFluid: $windshieldFluid,
      brakeFluid: $brakeFluid,
      leakInspection: $leakInspection,
      oilFilterId: $oilFilterId,
      cabinFilterId: $cabinFilterId,
      engineFilterId: $engineFilterId
    ) {
      id
    }
  }
`;

export const GET_SUBSCRIPTION_TYPES = gql`
  query GetAllSubscriptionTypes {
    getAllSubscriptionTypes {
      id
      name
      description
      price
      frequency
      aliasName
    }
  }
`;

export const GET_USER_VEHICLE_LATEST_MILEAGE = gql`
  query GetLatestMileageByUserVehicleId($userVehicleId: Int!) {
    getLatestMileageByUserVehicleId(userVehicleId: $userVehicleId) {
      mileage
    }
  }
`;
